import React, { useState, useCallback, useEffect, TransitionEvent } from "react";
import { classNames } from "../common_util";

interface PopoverOptions {
  isFloatingOnDesktop?: boolean;
  isFullHeightOnMobile?: boolean;
  isModal?: boolean;
  extraClasses?: string[];
}

const defaultPopoverOptions = {
  isFloatingOnDesktop: false,
  isFullHeightOnMobile: false,
  isModal: false,
  extraClasses: []
}

const Popover = ({
  unexpandedContent,
  expandedContent,
  onTransition,
  options = {},
  extraAttrs = {}
} : {
  unexpandedContent: (showPopover: () => void) => JSX.Element;
  expandedContent: (hidePopover: () => void) => JSX.Element;
  onTransition?: (() => void);
  options?: PopoverOptions;
  extraAttrs?: { [key: string]: string };
}) : JSX.Element => {
  const [isExpanded, setExpanded] = useState(false);

  const hidePopover = useCallback(() => setExpanded(false), [setExpanded]);
  const showPopover = useCallback(() => setExpanded(true), [setExpanded]);

  const handleTransitionEndFunc = (event: TransitionEvent<HTMLDivElement>) => {
    if (event.target == event.currentTarget && onTransition) {
      onTransition();
    }
  }

  const {
    isFloatingOnDesktop,
    isFullHeightOnMobile,
    isModal,
    extraClasses
  } = Object.assign({ ...defaultPopoverOptions }, options);

  const classes = classNames({
    "popover-sheet": true,
    "is-full-height-below-mobile": isFullHeightOnMobile,
    "is-active": isExpanded,
    "is-floating-above-desktop": isFloatingOnDesktop,
    "is-modal": isModal,
  }) + ` ${extraClasses.join(" ")}`;

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setExpanded(false);

        if (event.target == event.currentTarget && onTransition) {
          // add on popover
          document.addEventListener('transitionend', () => {
            onTransition();
          })
        }
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [setExpanded, onTransition])

  return (
    <div {...extraAttrs} className={classes} data-testid="popover">
      {unexpandedContent(showPopover)}

      <div className="shim" onClick={hidePopover}></div>

      <div className="surface" onTransitionEndCapture={handleTransitionEndFunc}>
        <div className="content">
          {expandedContent(hidePopover)}
        </div>
      </div>
    </div>
  );
}

export { Popover };
